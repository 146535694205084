import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Seo from 'gatsby-plugin-wpgraphql-seo';
import Enews from "../components/enews";
import MyLocation from "../components/myLocation";
import NearestDentist from "../components/nearestDentist";
import FixedImage from "../components/fixedImage";
import FluidImage from "../components/fluidImage";
import WPLink from "../components/wpLink";
import prepareSchemaHours from "../helpers/prepareSchemaHours";

import styles from "./index.module.scss";

// SVG 
import arrowIconRed from '../images/svg-icons/arrow-red.svg'
import arrowIconOrange from '../images/svg-icons/arrow-orange.svg'
import arrowIconGray from '../images/svg-icons/arrow-gray.svg'
import arrowIconPurple from '../images/svg-icons/arrow-purple.svg'


const IndexPage = ({sticky=true}) => {
	const [schemaData, setSchemaData] = React.useState(null);

	const data = useStaticQuery(graphql`
		query IndexPageQuery {
			home: wpPage(isFrontPage: {eq: true}) {
				seo {
					title
					metaDesc
					focuskw
					metaKeywords
					metaRobotsNoindex
					metaRobotsNofollow
					opengraphTitle
					opengraphDescription
					opengraphImage {
						altText
						sourceUrl
						srcSet
					}
					twitterTitle
					twitterDescription
					twitterImage {
						altText
						sourceUrl
						srcSet
					}
					canonical
					cornerstone
					schema {
						articleType
						pageType
						raw
					}
				}
				ACFHomePage {
					hero {
						images {
							mobileBackgroundImage {
								altText
								localFile {
									childImageSharp {
										fluid(maxWidth: 699){
											...GatsbyImageSharpFluid_withWebp_noBase64
										}
									}
									publicURL
                					extension
								}
							}
							backgroundImageTablet: backgroundImage {
								altText
								localFile {
									childImageSharp {
										fluid(maxWidth: 999){
											...GatsbyImageSharpFluid_withWebp_noBase64
										}
									}
									publicURL
                					extension
								}
							}
							backgroundImageDesktop: backgroundImage {
								altText
								localFile {
									childImageSharp {
										fluid(maxWidth: 1399){
											...GatsbyImageSharpFluid_withWebp_noBase64
										}
									}
									publicURL
                					extension
								}
							}
							backgroundImageXL: backgroundImage {
								altText
								localFile {
									childImageSharp {
										fluid(maxWidth: 2000){
											...GatsbyImageSharpFluid_withWebp_noBase64
										}
									}
									publicURL
                					extension
								}
							}
						}
						content {
							headingImage {
								altText
								localFile {
									childImageSharp {
										fluid(maxWidth: 1000){
											...GatsbyImageSharpFluid_withWebp_noBase64
										}
									}
									publicURL
                					extension
								}
							}
							blurb
							link {
								target
								title
								url
							}
						}
						quickLinks {
							links {
								link {
									target
									title
									url
								}
							}
					  	}
					}
					intro {
						left {
						  blurb
						  heading
						  fieldGroupName
						  list {
							heading
							shortParagraph
							link {
							  target
							  title
							  url
							}
						  }
						}
						right {
						  altText
						  localFile {
							childImageSharp {
								fluid(maxWidth: 600){
									...GatsbyImageSharpFluid_withWebp_noBase64
								}
							}
							publicURL
                			extension
						  }
						}
					}
					ourTeam {
						heading 
						teamLink {
							link {
								url
								title
							}
						}
					}
					insuranceLogoSection {
						... on WpInsurance {
						  id
						  title
						  ACFInsurance {
							logo {
							  altText
							  id
							  localFile {
								childImageSharp {
								  fluid(maxWidth: 90){
									...GatsbyImageSharpFluid_withWebp
								  }
								}
								publicURL
                				extension
							  }
							}
						  }
						}
					}
					services {
						left {
						  backgroundImageMobile: backgroundImage{
							altText
							localFile {
							  childImageSharp {
								  fluid(maxWidth: 699){
									...GatsbyImageSharpFluid_withWebp
								  }
							  }
							  publicURL
                			  extension
							}
						  }
						  backgroundImageTablet: backgroundImage {
							altText
							localFile {
							  childImageSharp {
								  fluid(maxWidth: 999){
									...GatsbyImageSharpFluid_withWebp
								  }
							  }
							  publicURL
                			  extension
							}
						  }
						  backgroundImageDesktop: backgroundImage {
							altText
							localFile {
							  childImageSharp {
								  fluid(maxWidth: 595){
									...GatsbyImageSharpFluid_withWebp
								  }
							  }
							  publicURL
                			  extension
							}
						  }
						  backgroundImageXL: backgroundImage {
							altText
							localFile {
							  childImageSharp {
								  fluid(maxWidth: 850){
									...GatsbyImageSharpFluid_withWebp
								  }
							  }
							  publicURL
                			  extension
							}
						  }
						  heading
						  paragraphBlurb
						  link {
							target
							title
							url
						  }
						}
						selectedServices {
							... on WpService {
								link
								title
								id
								ACFService {
									briefDescription
									serviceIcon {
										altText
										localFile {
											childImageSharp {
												fluid {
													...GatsbyImageSharpFluid_withWebp
												}
											}
											publicURL
                							extension
										}
									}
								}
							}
						}
					}
					enewsAndLocations {
						form {
						  heading
						  blurb
						}
					}
				}
			}
			options: wp {
				headerSection {
					ACFHeaderOptions {
						mainNavigationSettings {
							headerLogo {
								altText
								localFile {
									childImageSharp {
										fluid(maxWidth: 420){
											...GatsbyImageSharpFluid_withWebp
										}
									}
									publicURL
                					extension
								}
							}
						}
					}
				}
			}
			dentists: allWpDentist(sort: {fields: title, order: ASC}) {
				nodes {
				  id
				  title
					ACFDentist {
						lastName
						headshot {
							localFile {
								childImageSharp {
									fluid(maxWidth: 280){
										...GatsbyImageSharpFluid_withWebp
									}
								}
							}
						}
					}
				  link
				}
			}
			plaqusterKevin: file(name: {eq: "kevin"}) {
				childImageSharp {
					fluid(maxWidth: 210){
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			plaqusterJake: file(name: {eq: "jake"}) {
				childImageSharp {
					fluid(maxWidth: 140){
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			plaqusterRojo: file(name: {eq: "rojo"}) {
				childImageSharp {
					fluid(maxWidth: 160){
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
			rightArrowYellow: file(name: {eq: "arrow"}) {
				childImageSharp {
				  fixed(width: 9) {
					...GatsbyImageSharpFixed_withWebp
				  }
				}
			}
			rightArrowYellowOffsite: file(name: {eq: "arrow_offsite"}) {
				childImageSharp {
				  fixed(width: 10) {
					...GatsbyImageSharpFixed_withWebp
				  }
				}
			}
			rightArrowBlue: file(name: {eq: "arrow_blue"}) {
				childImageSharp {
				  fixed(width: 9) {
					...GatsbyImageSharpFixed_withWebp
				  }
				}
			}
			arrowRedLocal: file(name: {eq: "arrow_red_local"}) {
				childImageSharp {
				  fixed(width: 10) {
					...GatsbyImageSharpFixed_withWebp
				  }
				}
			}
			arrowGrayLocal: file(name: {eq: "arrow_gray_local"}) {
				childImageSharp {
				  fixed(width: 10) {
					...GatsbyImageSharpFixed_withWebp
				  }
				}
			}
			locationIcon: file(name: {eq: "location_icon"}) {
				childImageSharp {
				  fixed(width: 14) {
					...GatsbyImageSharpFixed_withWebp
				  }
				}
			}
			locations: allWpLocation(sort: {fields: title, order: ASC}) {
				nodes {
				  id
				  title
				  link
					birdeyeLocation {
						timezone
						phone
						name
						location {
							lat
							lng
							city
							state
							zip
							address1
							address2
						}
						hoursOfOperations {
							day
							isOpen
							workingHours {
								startHour
								endHour
							}
						}
					}
				  wpDentists {
					  ACFDentist {
							lastName
						  briefDescription
							headshot {
								localFile {
									childImageSharp {
										fluid(maxWidth: 280){
											...GatsbyImageSharpFluid_withWebp
										}
									}
								}
							}
					  }
						title
						link
				  }
				  ACFLocation {
					bookMyAppointmentLink {
					  url
					  title
					  target
					}
					menuTitle
					briefDescription
					locationInfo {
							left {
								dentists {
									... on WpDentist {
										id
										ACFDentist {
											lastName
											headshot {
												localFile {
													childImageSharp {
														fluid(maxWidth: 280){
															...GatsbyImageSharpFluid_withWebp
														}
													}
												}
											}
											briefDescription
										}
										title
										link
									}
								}
							}
						}
				  }
				}
			}
		}
	`);

	const {
		home,
		home: {
			ACFHomePage: {
				hero: {
					images: {
						mobileBackgroundImage: heroBGMobile,
						backgroundImageTablet: heroBGTablet,
						backgroundImageDesktop: heroBGDesktop,
						backgroundImageXL: heroBGXL,
					},
					content: {
						headingImage,
						blurb,
						link,
					},
					quickLinks: {
						links: heroQuickLinks
					}
				},
				intro: {
					left: {
						blurb: introLeftBlurb,
						heading: introLeftHeading,
						list: introLeftList
					},
					right: introImg
				},
				ourTeam: {
					heading: teamHeading,
					teamLink: {
						link: teamLink,
					}
				},
				insuranceLogoSection,
				services: {
					left: {
					  backgroundImageMobile: servicesBackgroundImageMobile, 
					  backgroundImageTablet: servicesBackgroundImageTablet,
					  backgroundImageDesktop: servicesBackgroundImageDesktop,
					  backgroundImageXL: servicesBackgroundImageXL,
					  heading: servicesHeading,
					  paragraphBlurb: servicesParagraph,
					  link: servicesLink 
				  	},
					selectedServices
				},
				enewsAndLocations: {
					form: {
					  heading: enewsHeading,
					  blurb: enewsBlurb
					}
				},
			},
		},
		options: {
			headerSection: {
				ACFHeaderOptions: {
					mainNavigationSettings: {
						headerLogo
					}
				}
			}
		},
		plaqusterKevin,
		plaqusterJake,
		plaqusterRojo,
		rightArrowYellow,
		rightArrowYellowOffsite,
		rightArrowBlue,
		arrowRedLocal,
		arrowGrayLocal,
		locationIcon,
		dentists: {
			nodes: dentistNodes
		},
		locations: {
			nodes: locationNodes
		},
	} = data;

	useEffect(() => {
		if (locationNodes.length < 1) {
			return;
		}

		const bowmanLocation = locationNodes.find(location => location.title.includes('Bowman'));
		const nonBowmanLocations = locationNodes.filter(location => !location.title.includes('Bowman'));

		if (!bowmanLocation) {
			return;
		}

		const deparments = nonBowmanLocations.map(location => {
			const deptHours = prepareSchemaHours(location.birdeyeLocation.hoursOfOperations);
			return `{
				"@context": "https://schema.org",
				"@type": "Dentist",
				"name": "${location.birdeyeLocation.name}",
				"url": "${location.link}",
				"telephone": "${location.birdeyeLocation.phone}",
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "${location.birdeyeLocation.location.address1} ${location.birdeyeLocation.location.address2}",
					"addressLocality": "${location.birdeyeLocation.location.city}",
					"addressRegion": "${location.birdeyeLocation.location.state}",
					"postalCode": "${location.birdeyeLocation.location.zip}",
					"addressCountry": "US"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": ${location.birdeyeLocation.location.lat},
					"longitude": ${location.birdeyeLocation.location.lng}
				},
				"openingHours": [${deptHours}]
			}`;
		});

		const bowmanLocHours = prepareSchemaHours(bowmanLocation.birdeyeLocation.hoursOfOperations);
		const newSchema = `{
			"@context": "https://schema.org",
			"@type": "Dentist",
			"name": "${bowmanLocation.birdeyeLocation.name}",
			"url": "${bowmanLocation.link}",
			"telephone": "${bowmanLocation.birdeyeLocation.phone}",
			"address": {
				"@type": "PostalAddress",
				"streetAddress": "${bowmanLocation.birdeyeLocation.location.address1} ${bowmanLocation.birdeyeLocation.location.address2}",
				"addressLocality": "${bowmanLocation.birdeyeLocation.location.city}",
				"addressRegion": "${bowmanLocation.birdeyeLocation.location.state}",
				"postalCode": "${bowmanLocation.birdeyeLocation.location.zip}",
				"addressCountry": "US"
			},
			"geo": {
				"@type": "GeoCoordinates",
				"latitude": ${bowmanLocation.birdeyeLocation.location.lat},
				"longitude": ${bowmanLocation.birdeyeLocation.location.lng}
			},
			"openingHours": [${bowmanLocHours}],
			"department": [${deparments}]
		}`;

		setSchemaData(newSchema);
	}, []);

	return (
		<Layout isHomepage={true}>
			<Seo post={home} />
			<Helmet>
				{schemaData && <script type="application/ld+json">
					{schemaData}
				</script>}
			</Helmet>
      		<section className={styles.hero}>
        		<h1 className="homeh1">Leap Kids Dental</h1>
				{ headerLogo && <FluidImage image={headerLogo} passedClass={styles.headerlogo} loading={"eager"} fadeIn={false} /> }
				<WPLink link={{target: '', title: 'Make an Appointment', url: '/schedule-online/'}} passedClass={styles.mobileapptlink} passedIconClass={styles.icon} />
				<div className={styles.kevin}>
					<FluidImage image={plaqusterKevin} passedClass={styles.kevin} loading={"eager"} fadeIn={false} alternativeText={'Kevin the plaquster, a Leap dental brand character'} />
				</div>
				<div className={styles.jake}>
					<FluidImage image={plaqusterJake} passedClass={styles.jake} loading={"eager"} fadeIn={false} alternativeText={'Jake the plaquster, a Leap dental brand character'} />
				</div>
				<div className={styles.top}>
					{ heroBGMobile && <><div className={styles.heroOverlay}></div><FluidImage image={heroBGMobile} passedClass={styles.mobilebg} loading={"eager"} fadeIn={false} /></> }
					{ heroBGTablet && <><div className={styles.heroOverlay}></div><FluidImage image={heroBGTablet} passedClass={styles.bgtablet} loading={"eager"} fadeIn={false} /></> }
					{ heroBGDesktop && <><div className={styles.heroOverlay}></div><FluidImage image={heroBGDesktop} passedClass={styles.bgdesktop} loading={"eager"} fadeIn={false} /></> }
					{ heroBGXL && <><div className={styles.heroOverlay}></div><FluidImage image={heroBGXL} passedClass={styles.bgxl} loading={"eager"} fadeIn={false} /></> }
					<div className={styles.content}>
						{ headingImage && <FluidImage image={headingImage} passedClass={styles.headingimg} loading={"eager"} fadeIn={false} /> }
						{ blurb && <div className={styles.blurb} dangerouslySetInnerHTML={{__html: blurb }}></div> }
						{ link && <WPLink link={link} passedClass={styles.link} icon={arrowIconOrange} iconIsSVG={true} /> } 
					</div>
				</div>
				<div className={styles.bottom}>
					<div className={styles.quicklinks} data-sal="slide-up" data-sal-delay="1200" data-sal-easing="ease-in">
					{heroQuickLinks && heroQuickLinks.map((link, index) => {
						return <WPLink key={`quicklink-${index}`} link={link.link} passedClass={styles.link} icon={arrowIconRed} iconIsSVG={true} />;
					})}
					</div>
				</div>

			</section>

			<section className={styles.intro}>
				<div className={styles.left}>
					{ introLeftHeading && <h2 className={styles.heading} dangerouslySetInnerHTML={{__html: introLeftHeading}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></h2> }
					{ introLeftBlurb && <div className={styles.blurb} dangerouslySetInnerHTML={{__html: introLeftBlurb}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></div> }
					{ introLeftList && (
						<ul className={styles.list}>
							{ introLeftList.map( (listitem, _index) => {
								return (
									<li key={listitem.heading} className={styles.listitem} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
										<FixedImage image={rightArrowBlue} passedClass={styles.icon} />
										{ listitem.heading && <span className={styles.listheading} dangerouslySetInnerHTML={{__html: listitem.heading }}></span> }
										{ listitem.shortParagraph && <p className={styles.paragraph} dangerouslySetInnerHTML={{__html: listitem.shortParagraph}}></p> }
										{ listitem.link && <WPLink link={listitem.link} passedClass={styles.link} icon={rightArrowYellowOffsite} passedIconClass={styles.icon} /> }
									</li>
								)
							})}
						</ul>
					)}
				</div>
				{ introImg && <div className={`${styles.introimagewrap}`}>
					<FluidImage data-sal="slide-up" data-sal-delay="1600" data-sal-easing="ease-in" passedClass={styles.image} image={introImg} />
				</div>
				}
			</section>

			{ teamHeading && (
				<section className={styles.ourteam}>
					<div className={styles.left}>
						{teamHeading && <h2 className={styles.sectionheading}>{teamHeading}</h2>}
						{teamLink && <a href={teamLink.url} className={styles.teamlink}>{teamLink.title} <img src={arrowIconGray} loading="lazy" alt="" /></a>}
					</div>
					<div className={styles.right}>
						<NearestDentist 
							locations={locationNodes} 
							dentists={dentistNodes} 
							arrowRedLocal={arrowRedLocal} 
							arrowGrayLocal={arrowGrayLocal} 
							inMegaMenu={false}
						/> 
					</div>
				</section>
			)}

			{ insuranceLogoSection && (
				<section className={styles.insurance} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in" >
					{ insuranceLogoSection.map( (insurance, _index) => {
						return <FluidImage key={`insurancelogo-${insurance.ACFInsurance.logo.id}`} passedClass={styles.logo} image={insurance.ACFInsurance.logo} />;
					})}
				</section> 
			)}

			<section className={styles.services}>
				<div className={styles.rojo}>
					<FluidImage image={plaqusterRojo} loading={"eager"} fadeIn={false} alternativeText={'Rojo the plaquster, a Leap dental brand character'} />
				</div>
				<div className={styles.left}>
						{ servicesBackgroundImageMobile && <FluidImage image={servicesBackgroundImageMobile} passedClass={styles.bgmobile} /> }
						{ servicesBackgroundImageTablet && <FluidImage image={servicesBackgroundImageTablet} passedClass={styles.bgtablet} /> }
						{ servicesBackgroundImageDesktop && <FluidImage image={servicesBackgroundImageDesktop} passedClass={styles.bgdesktop} /> }
						{ servicesBackgroundImageXL && <FluidImage image={servicesBackgroundImageXL} passedClass={styles.bgxl} /> }
					<div className={styles.leftwrap}>

						{ servicesHeading && <h2 className={styles.heading} dangerouslySetInnerHTML={{__html: servicesHeading}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></h2> }
						{ servicesParagraph && <p dangerouslySetInnerHTML={{__html: servicesParagraph}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></p> }
						{ servicesLink && <WPLink link={servicesLink} passedClass={styles.link} icon={arrowIconPurple} iconIsSVG={true} passedIconClass={styles.icon} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in" />}
					</div>
				</div>
				<div className={styles.right}>
					{ selectedServices && (
						<div className={styles.servicescontainer}>
							{ selectedServices && selectedServices.map((service, _index) => {


								return (
									<div key={`ftsrvc-'${service.id}`} className={styles.featuredservice} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
										<Link to={service.link} className={styles.title}>
											<h3>{service.title}
												<FixedImage image={rightArrowYellow} passedClass={styles.icon} /> 
											</h3>
											{ service.ACFService.briefDescription && <p dangerouslySetInnerHTML={{__html: service.ACFService.briefDescription}}></p>}
										</Link>
									</div>
								)
							})}
						</div>
					)}
				</div>
			</section>
			<section className={styles.enewslocations}>
				<div className={styles.form}>
					<div data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
						{ enewsHeading && <h2 className={styles.heading} dangerouslySetInnerHTML={{__html: enewsHeading}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></h2> }
						{ enewsBlurb && <p dangerouslySetInnerHTML={{__html: enewsBlurb}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></p> }
						<Enews data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in" />
					</div>
				</div>
				<div className={styles.location}>
					<div className={styles.left}>
						<MyLocation locations={locationNodes} arrowRedLocal={arrowRedLocal} arrowGrayLocal={arrowGrayLocal} locationIcon={locationIcon} rightArrowYellowOffsite={rightArrowYellowOffsite} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in" />
					</div>
					<div className={styles.right}>
						<h3 className={styles.heading} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">Find more locations.</h3>
						<ul className={styles.list}>
							{ locationNodes && locationNodes.map((location, index) => {
								return (
									<li key={`lstloc-'${location.id}`} className={styles.listitem} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
										<Link to={location.link} className={styles.link}>
											<svg xmlns="http://www.w3.org/2000/svg" width="8" height="7" viewBox="0 0 8 7" className={styles.icon}>
												<g fill="none" fillRule="evenodd">
													<g fill="#FBB03B" className={styles.iconarrow}>
														<g>
															<g>
																<g>
																	<path d="M12.266 226.266L13 227 16.5 223.5 13 220 12.266 220.734 14.505 222.974 9.5 222.974 9.5 224.026 14.505 224.026z" transform="translate(-1024 -1130) translate(1015 215) translate(0 629) translate(0 66)"/>
																</g>
															</g>
														</g>
													</g>
												</g>
											</svg>
											<span className={styles.title} dangerouslySetInnerHTML={{__html: location.ACFLocation.menuTitle ? `${location.ACFLocation.menuTitle}` : `${location.birdeyeLocation.location.city}, ${location.birdeyeLocation.location.state}`}}></span>
										</Link>	
									</li>
								)
							})}
						</ul>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default IndexPage;
